.loader-container {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  background: rgba(138, 116, 152,0.7);
  z-index: 1;
  /* animation: wrapoff 0.5s linear forwards;
  animation-delay: 1.5s;
  animation-iteration-count: infinite; */
}
@keyframes wrapoff {
  0% {
    opacity: 0.9;
  }
  50%{
    opacity: 0.6;
  }
  100%{
    opacity: 0;
  }
}

.spinner {
    width: 100px;
    height: 100px;
    text-align: center;
    padding: 20px;
    transform: rotate(-45deg);
}
.spinner::before {
  content: "";
  position: absolute;
  top: -7px;
  right: -7px;
  width: 0;
  height: 0;
  background: transparent;
  border: 7px solid transparent;
  animation: animate 1.5s linear forwards;
  animation-iteration-count: infinite;
}
/* .spinner:hover::before {
  
} */
@keyframes animate {
  0% {
    width: 0;
    height: 0;
    border-top-color: #4e5e72;
    border-right-color: transparent;
    border-bottom-color: transparent;
    border-left-color: transparent;
  }

  16.65% {
    width: 100%;
    height: 0;
    border-top-color: #4e5e72;
    border-left-color: #4e5e72;
    border-bottom-color: transparent;
    border-right-color: transparent;
  }

  33.3%, 100% {
    width: 100%;
    height: 100%;
    border-top-color: #4e5e72;
    border-left-color: #4e5e72;
    border-bottom-color: transparent;
    border-right-color: transparent;
  }
}


.spinner::after {
  content: "";
  position: absolute;
  bottom: -7px;
  left: -7px;
  width: 0;
  height: 0;
  background: transparent;
  border: 7px solid transparent;
  animation: animates 1.5s linear forwards;
  /* animation-delay: 0.5s; */
  animation-iteration-count: infinite;
}



@keyframes animates {
  0% {
    width: 0;
    height: 0;
    border-top-color: transparent;
    border-bottom-color: transparent;
    border-left-color: transparent;
    border-right-color: transparent;
  }
    
  33.3% {
    width: 0;
    height: 0;
    border-top-color: transparent;
    border-bottom-color: #4e5e72;
    border-left-color: transparent;
    border-right-color: transparent;
  }

  49.95% {
    width: 100%;
    height: 0;
    border-top-color: transparent;
    border-left-color: transparent;
    border-bottom-color: #4e5e72;
    border-right-color: #4e5e72;
  }

  66.6%, 100% {
    width: 100%;
    height: 100%;
    border-top-color: transparent;
    border--color: transparent;
    border-bottom-color: #4e5e72;
    border-right-color: #4e5e72;
  }
}

.loaderInner{
  display: block;
  position: relative;
  width: 100%;
  height: 100%;
  margin: auto;
  color: black;
  transform: rotate(90deg);
}
.loaderInner::after {
  content: "";
  position: absolute;
  bottom: -6px;
  left: -6px;
  width: 0;
  height: 0;
  background: transparent;
  border: 6px solid transparent;
  animation: animateV 1.5s linear forwards;
  /* animation-delay: 1s; */
  animation-iteration-count: infinite;
}



@keyframes animateV {
  0% {
    width: 0;
    height: 0;
    border-top-color: transparent;
    border-bottom-color: transparent;
    border-left-color: transparent;
    border-right-color: transparent;
  }

  66.6% {
    width: 0;
    height: 0;
    border-top-color: transparent;
    border-bottom-color: #4e5e72;
    border-left-color: transparent;
    border-right-color: transparent;
  }

  83.25% {
    width: 100%;
    height: 0;
    border-top-color: transparent;
    border-left-color: transparent;
    border-bottom-color: #4e5e72;
    border-right-color: #4e5e72;
  }

  100% {
    width: 100%;
    height: 100%;
    border-top-color: transparent;
    border--color: transparent;
    border-bottom-color: #4e5e72;
    border-right-color: #4e5e72;
  }
}
/* @keyframes spin-anim {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
} */