@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Darker+Grotesque:wght@600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Darker+Grotesque:wght@600&display=swap');

*{
  box-sizing: border-box !important;
}
html {
  scroll-behavior: smooth;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #B3A1A1;
  font-family: 'Poppins', sans-serif !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
h2{
      color: #8B7486;
}
h1, h2{
  font-size-adjust: 100%;
  /* font-family: 'Darker Grotesque', sans-serif !important; */
  font-family: 'Roboto Slab', serif !important;
}
a,
a:hover,
a:focus,
a:active {
  text-decoration: none;
  -webkit-tap-highlight-color: transparent;
}
/* .fslightbox-container{
 transform: translate(-50%, -50%);
  height: 100vh;
} */
@media screen and (max-width:899px) {
  .Nav .logoSection:nth-of-type(1){
    display: none !important;
  }
  .Nav .homeSmall.logoSection {
    display: flex !important;
    margin: auto;
  }
}
@media screen and (max-width:450px) {
  .small.logoSection div:nth-child(1) {
    display: none;
  }
  .small.logoSection div:nth-child(2) {
    width: 120px !important;
  }
}
