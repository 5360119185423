
.navContainer{
    background-color: #F5F6FA;
    
}
.Nav{
    top: 0;
    z-index: 1000;
    position: sticky;
    position: -webkit-sticky;
    background-color: #e2e2e0 !important;
    /* background-color: #fff !important   ; */
    /* border-bottom: 1px solid black; */
}
.navHome{
    background-color: #F5F6FA;
    /* box-shadow: 11px 11px 22px #e9eaee,
            -11px -11px 22px #ffffff !important; */
}
.css-v6pmmq-MuiPaper-root-MuiAppBar-root{
    box-shadow: 11px 11px 22px #e9eaee,
            -11px -11px 22px #ffffff !important;
}
/* .css-v6pmmq-MuiPaper-root-MuiAppBar-root {
    box-shadow: -11px -11px 22px #e9eaee,
        11px 11px 22px #ffffff !important;
} */

.navElDesk a, .navElMob a{
    color: #4e5e72;
    text-decoration: none;
}
.navElDesk a:hover, .navElMob a:hover{
    color: #1b2a3b;
}
.navElMob li{
    width: 100%;
    padding: 0;
    margin: 0;
    margin-block: 0;
}
.navElMob{
    width: 100%;

}
.navElMob .css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper{
    background-color: #F5F6FA !important;
}
/* .Nav{
    margin: auto;
    text-align: center;
    width: fit-content;
    display: flex;
    flex-direction: row;
    justify-content: center;
    border-bottom: 1px solid black;
} */
/* .Nav img{
    width: 30px;
}
.mobile{
    border-bottom: 20px;
    margin: auto;
    display: flex;
    flex-direction: row;
}
.menu{
    display: none;
}
.Nav ul{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}
.Nav li{
    text-transform: uppercase;
    font-size: 1em;
    display: inline;
    margin: 0 5px;
    padding: 15px;
    list-style-type: none;
    font-weight:bolder;
} */


/* @media screen and (max-width:1200px) {
    .Nav li {
        font-size: 0.7em;
        display: inline;
        margin: 0 3px;
        padding: 10px;
    }
    
} */

/* header .navbar {
    margin: auto;
    width: 100%;
    padding: 35px 50px;
    border-radius: 25px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.navbar .menu {
    display: flex;
    flex-wrap: wrap;
}

.navbar .logo a {
    text-decoration: none;
    font-size: 22px;
    color: #000;
    font-weight: 500;
}

.navbar .menu li {
    list-style: none;
    margin: 0 6px;
}

.navbar .menu a {
    color: #000;
    text-decoration: none;
    font-size: 17px;
    font-weight: 500;
    transition: all 0.3s ease;
}

.navbar .menu a:hover {
    color: #f2f2f2;
}

.navbar .buttons input {
    outline: none;
    color: #f2f2f2;
    font-size: 18px;
    font-weight: 500;
    border-radius: 12px;
    padding: 6px 15px;
    border: none;
    margin: 0 8px;
    cursor: pointer;
    transition: all 0.3s ease;
    background-image: linear-gradient(135deg, #ff9a9e 10%, #F6416C 100%);
}

.navbar .buttons input:hover {
    transform: scale(0.97);
} */